import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 20],
};

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home-layout/home-layout.module').then(m => m.HomeLayoutModule)
  }
];

@NgModule({ 
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
